<template>
   <YLayoutBodyMenu :menu="[
       {title:'基础配置',path:'/shop/plugin/area-agent/conf'},
       {title:'代理管理',path:'/shop/plugin/area-agent/manage'},
       {title:'升级商品',path:'/shop/plugin/area-agent/goods'},
   ]">
     <router-view/>
   </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "index",
  components: {YLayoutBodyMenu},
}
</script>

<style scoped>

</style>